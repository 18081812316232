import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer, Meta, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { HreflangService } from 'app/services/hreflang.service';
import { BlogClass } from 'app/shared/blogs';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit, OnDestroy {
  item: Observable<BlogClass>; // Observable for news item
  activeLang: string = 'EN';
  blog: any = '';
  firstRun: boolean;
  scriptElement;
  private langChangesSubscription: Subscription;

  constructor(public router: ActivatedRoute, private _translocoService: TranslocoService, private sanitizer: DomSanitizer,
    private hreflangService: HreflangService, private meta: Meta, private renderer: Renderer2) { }

  ngOnInit(): void {
    if (typeof document === 'undefined') {
      return; // Not running in the browser, do nothing
    }
    this.getActiveLang();
    this.item = this.router.data.pipe(map((data: any) => data.blog));
    this.item.subscribe((blog: BlogClass) => {
      this.blog = blog;
      this.blog.description[this.activeLang] = this.sanitizer.bypassSecurityTrustHtml(this.blog.description[this.activeLang])
      this.addOrganizationSchema(this.blog);
    });
  }

  getActiveLang() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      if (this.activeLang !== activeLang || !this.firstRun) {
        this.deleteSchemaMeta();
        setTimeout(() => {
          let url = this.router.snapshot.params['id'];
          this.hreflangService.updateHreflangTags(activeLang, `blogs/${url}`);
        }, 1000);
        this.firstRun = true;
      }

      this.activeLang = activeLang;
    });
  }


  addOrganizationSchema(blog) {
    if (typeof document === 'undefined') {
      return; // Not running in the browser, do nothing
    }

    const script = this.renderer.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": `https://zirvecompany.net/${this.activeLang}/blogs/${blog._id}`
      },
      "headline": `"${blog.title[this.activeLang]}"`,
      "image": [
        `${blog.image.url}`
      ],
      "datePublished": `"${blog.createdAt}"`,
      "dateModified": `"${blog.updatedAt}"`,
      "author": {
        "@type": "Person",
        "name": "AUTHOR_NAME"
      },
      "publisher": {
        "@type": "Organization",
        "name": "Zirve Packaging",
        "logo": {
          "@type": "ImageObject",
          "url": "https://msr.zirvecompany.net/images/logo.png"
        }
      },
      "description": `${blog.description[this.activeLang]}`

    });
    this.scriptElement = script;
    this.renderer.appendChild(document.head, script);


  }



  // getSafeHtml(html: string): SafeHtml {
  //   return this.sanitizer.bypassSecurityTrustHtml(html);
  // }

  ngOnDestroy() {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
    this.deleteSchemaMeta();
    // Delete Robots
    const robotTags = this.meta.getTags('name="robots"');
    if (robotTags.length > 0) {
      robotTags.forEach(tag => {
        try {
          this.meta.removeTagElement(tag);
        } catch (error) {
          console.error('Error removing robot tag:', error);
        }
      });
    }
  }

  deleteSchemaMeta() {
    if (this.scriptElement) {
      this.renderer.removeChild(document.head, this.scriptElement);
      this.scriptElement = null;
    }
    // Delete Hreflang
    const hreflangTags = this.meta.getTags('name="hreflang"');
    if (hreflangTags.length > 0) {
      hreflangTags.forEach(tag => {
        try {
          this.meta.removeTagElement(tag);
        } catch (error) {
          console.error('Error removing hreflang tag:', error);
        }
      });
    }


    // Delete Canonical
    try {
      this.meta.removeTag('rel=canonical');
    } catch (error) {
      console.error('Error removing canonical tag:', error);
    }
  }
}
