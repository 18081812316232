import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    constructor(private router: Router) { }

    setLanguage(lang: string) {
        // Redirect to the same route with the specified language code
        this.router.navigateByUrl(this.router.url.replace(/\/[a-zA-Z]{2}\//, `/${lang}/`));
    }
}
