<div class="w-full h-full pb-1 bg-gray-900">
  <div class="flex items-center justify-center pt-8">
    <a
      class="text-white w-full text-center px-2"
      target="_blank"
      href="https://www.google.com/maps/place/Z%C4%B0RVE+%C4%B0NTERNAT%C4%B0ONAL/@37.0635676,37.4491954,17z/data=!4m5!3m4!1s0x1531e14ed879e753:0x8a654126dfac1b85!8m2!3d37.0635676!4d37.4513841"
    >
      <i class="fa-solid fa-location-dot"></i> : Sanayi Mah. 60352 sok . NO :29
      Şehitkamil / Gaziantep / Türkiye
    </a>
  </div>

  <div class="px-2 lg:px-10 xl:px-32 pt-8">
    <div
      class="grid md:flex lg:flex items-center justify-between max-md:justify-center bg-[#E60027] rounded-2xl"
    >
      <div class="w-1/2 max-md:w-full text-white py-6 px-10">
        <p class="text-xl font-bold">{{ "newsletter" | transloco }}</p>
        <p>{{ "enterYouEmailToGet" | transloco }}</p>
      </div>
      <div class="w-1/2 max-md:w-full max-md:mb-4 flex justify-center">
        <input
          class="p-3 w-5/6 lg:w-2/3 rounded-xl text-white bg-[#ffffff75]"
          type="email"
          [(ngModel)]="userEmail"
          [placeholder]="'enterYouEmail' | transloco"
        />
        <div class="flex items-center -ml-20">
          <button
            class="text-white h-8 px-3 bg-black rounded-xl"
            (click)="onSubmit()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
    <div class="grid lg:flex justify-between mt-10">
      <div class="max-md:w-full lg:w-5/12 xl:w-4/12">
        <div class="w-full grid xl:grid sm:px-10 lg:px-0 xl:px-0 items-center">
          <div class="flex items-center justify-around">
            <img
              class="mb-4 mr-1 sm:mr-5 lg:mr-0 xl:mr-0"
              src="assets\images\logo\oldLogo.webp"
              width="25%"
              alt=""
            />
            <img
              class="mb-4 mr-1 sm:mr-5 lg:mr-0 xl:mr-0"
              src="assets\home\qr.png"
              width="25%"
              alt=""
            />
          </div>

          <p class="text-[#959595]" [dir]="activeLang === 'AR' ? 'rtl' : 'ltr'">
            {{ "aboutZirveD" | transloco }}
          </p>
        </div>
      </div>
      <div
        class="max-sm:w-full lg:w-6/12 xl:w-5/12 max-md:mt-4 grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-5"
      >
        <div class="text-white">
          <p class="font-extrabold">{{ "generalPages" | transloco }}</p>
          <a
            class="block mt-4 hover:text-[#E60027]"
            [routerLink]="[activeLang, 'home']"
          >
            {{ "home" | transloco }}
          </a>
          <a
            class="block mt-4 hover:text-[#E60027]"
            [routerLink]="[activeLang, 'equipment_categories']"
          >
            {{ "equipmentCategories" | transloco }}
          </a>
          <a
            class="block mt-4 hover:text-[#E60027]"
            [routerLink]="[activeLang, 'news']"
          >
            {{ "news" | transloco }}</a
          >
          <a
            class="block mt-4 hover:text-[#E60027]"
            [routerLink]="[activeLang, 'blogs']"
          >
            {{ "blogs" | transloco }}</a
          >
          <a
            class="block mt-4 hover:text-[#E60027]"
            [routerLink]="[activeLang, 'contact']"
          >
            {{ "contact" | transloco }}</a
          >
        </div>
        <div class="text-white">
          <p class="font-extrabold">{{ "termsOfUse" | transloco }}</p>
          <a
            class="block mt-4 hover:text-[#E60027]"
            [routerLink]="['/', activeLang, 'privacyPolicy']"
          >
            {{ "privacyPolicy" | transloco }}
          </a>
          <a
            class="block mt-4 hover:text-[#E60027]"
            [routerLink]="['/', activeLang, 'cookies']"
          >
            {{ "cookies" | transloco }}
          </a>
          <a
            *ngIf="activeLang === 'TR'"
            class="block mt-4 hover:text-[#E60027]"
            [routerLink]="['/', activeLang, 'dataProtection']"
          >
            Kişisel Verilerin Korunması Kanunu
          </a>
        </div>
        <div class="text-white">
          <p class="font-extrabold">{{ "aboutUsN" | transloco }}</p>
          <a
            class="block mt-4 hover:text-[#E60027]"
            [routerLink]="[activeLang, 'about_company']"
          >
            {{ "aboutCompany" | transloco }}
          </a>
          <a
            class="block mt-4 hover:text-[#E60027]"
            [routerLink]="[activeLang, 'general_manager']"
          >
            {{ "generalManager" | transloco }}
          </a>
          <a
            class="block mt-4 hover:text-[#E60027]"
            [routerLink]="[activeLang, 'complaints_suggestions']"
          >
            {{ "ComplaintsSuggestions" | transloco }}
          </a>
        </div>
      </div>
    </div>

    <hr class="w-full text-white my-5" />
    <div
      class="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-32 justify-center text-center lg:justify-between xl:justify-between items-center text-white pb-5"
    >
      <div class="flex justify-around items-center">
        <a
          href="https://twitter.com/zirveextrusion"
          target="_blank"
          matTooltip="X"
        >
          <img
            class="mx-auto w-8"
            src="assets\contact\x.png"
            loading="lazy"
            alt="X Logo"
        /></a>
        <a
          href="https://www.facebook.com/zirveextrusion"
          target="_blank"
          matTooltip="Facebook"
        >
          <img
            class="mx-auto w-8"
            src="assets\contact\face.png"
            loading="lazy"
            alt="X Logo"
          />
        </a>
        <a
          href="https://www.instagram.com/zirveextrusion/"
          target="_blank"
          matTooltip="Instagram"
        >
          <img
            class="mx-auto w-8"
            src="assets\contact\insta.png"
            loading="lazy"
            alt="X Logo"
          />
        </a>
        <a
          href="https://www.youtube.com/channel/UCLvw13OLb5NJrE35Z9WkiRA"
          target="_blank"
          matTooltip="Youtube"
        >
          <img
            class="mx-auto w-8"
            src="assets\contact\youtube.png"
            loading="lazy"
            alt="X Logo"
          />
        </a>
        <a
          href="https://wa.me/+905301485665"
          target="_blank"
          matTooltip="Whatsapp"
        >
          <img
            class="mx-auto w-8"
            src="assets\contact\whatsapp.png"
            loading="lazy"
            alt="X Logo"
          />
        </a>

        <a
          href="https://www.tiktok.com/@zirveextrusion"
          target="_blank"
          matTooltip="Tiktok"
        >
          <img
            class="mx-auto w-8"
            src="assets\contact\tiktok.png"
            loading="lazy"
            alt="X Logo"
          />
        </a>
        <a
          href="https://www.linkedin.com/company/zirve-extrusion/about/"
          target="_blank"
          matTooltip="Linkedin"
        >
          <img
            class="mx-auto w-8"
            src="assets\contact\linkedin.png"
            loading="lazy"
            alt="X Logo"
          />
        </a>
        <a
          href="https://tr.pinterest.com/zirveextrusion/"
          target="_blank"
          matTooltip="Pinterest"
        >
          <img
            class="mx-auto w-8"
            src="assets\contact\preset.png"
            loading="lazy"
            alt="X Logo"
          />
        </a>
      </div>
      <div class="my-3">
        <span> Developed By: </span>
        <a
          class="text-[#58A3CE]"
          href="https://hazemdev.com"
          style="font-size: 16px"
          target="_blank"
        >
          Hazem Sweed
        </a>
      </div>
      <span> &copy; {{ date | date : "yyyy" }} All Rights Reserved</span>
    </div>
  </div>
</div>
