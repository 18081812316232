<div class="relative w-full top-0 h-[4.6rem] bg-gray-900"></div>

<div [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'">
  <div class="flex items-center justify-center mt-10 mb-4">
    <p
      class="text-center inline-block text-3xl border border-black px-5 py-1 rounded-full"
    >
      {{ "ComplaintsSuggestions" | transloco }}
    </p>
  </div>
  <div
    class="w-11/12 xl:w-9/12 center xl:p-8 rounded-2xl shadow-lg bg-[#dedede17]"
  >
    <form
      *ngIf="contactForm"
      [formGroup]="contactForm"
      (ngSubmit)="onSubmit()"
      autocomplete="off"
    >
      <div class="lg:flex">
        <mat-form-field class="mr-3 w-full" appearance="outline" id="nameInput">
          <mat-label> {{ "name" | transloco }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="name"
            placeholder="Enter your name"
            name="name"
          />
          <mat-error *ngIf="formErrors.name">{{ formErrors.name }}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-full" appearance="outline" id="lastNameInput">
          <mat-label> {{ "lastName" | transloco }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="lastName"
            placeholder="Enter your last name"
            name="lastName"
          />
          <mat-error *ngIf="formErrors.lastName">{{
            formErrors.lastName
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="lg:flex">
        <mat-form-field
          class="mr-3 w-full"
          appearance="outline"
          id="emailInput"
        >
          <mat-label> {{ "email" | transloco }}</mat-label>
          <input
            matInput
            type="email"
            formControlName="email"
            placeholder="Enter your email address"
            name="email"
          />
          <mat-error *ngIf="formErrors.email">{{ formErrors.email }}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-full" appearance="outline" id="countryInput">
          <mat-label> {{ "country" | transloco }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="country"
            placeholder="Enter your country"
            name="country"
          />
          <mat-error *ngIf="formErrors.country">{{
            formErrors.country
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="w-full">
        <mat-form-field class="w-full" appearance="outline" id="messageInput">
          <mat-label> {{ "message" | transloco }}</mat-label>
          <textarea
            matInput
            formControlName="message"
            placeholder="Enter your message"
            name="message"
          ></textarea>
          <mat-error *ngIf="formErrors.message">{{
            formErrors.message
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="py-3">
        <p *ngIf="successMSG" class="text-center text-green-600 text-xl">
          {{ successMSG }}
        </p>
        <button
          type="submit"
          [disabled]="contactForm.invalid"
          class="my-4 bg-[#E60027] text-white px-4 py-2 center rounded-md disabled:bg-[#ff798f] hover:bg-[#a7001c]"
        >
          {{ "sendMessage" | transloco }}
        </button>
      </div>
    </form>
  </div>
</div>

<div class="my-60"></div>
