import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ImageCacheService {
    private cache: { [url: string]: string } = {};
    public loadingProgress = new EventEmitter<{ done: boolean }>(); // Event emitter

    constructor(
        private http: HttpClient,) { }

    hasImage(url: string): boolean {
        return this.cache.hasOwnProperty(url);
    }

    getImage(url: string): string | null {
        return this.cache[url] || null;
    }

    storeImage(url: string, imageData: string) {
        this.cache[url] = imageData;
    }

    clearCache() {
        this.cache = {};
    }

    preloadImages(imageUrls: string[]) {
        const totalImages = imageUrls.length;
        let loadedImages = 0;
        imageUrls.forEach(url => {
            if (this.hasImage(url)) {
                loadedImages++;
                if (loadedImages === totalImages) {
                    this.loadingProgress.emit({ done: true }); // Signal completion
                }
                return; // Skip loading if image is already in cache
            }
            this.http.get(url, { responseType: 'blob' })
                .subscribe(imageBlob => {
                    const reader = new FileReader();
                    reader.readAsDataURL(imageBlob);
                    reader.onloadend = () => {
                        const base64data = reader.result as string;
                        this.storeImage(url, base64data);
                        loadedImages++;
                        if (loadedImages === totalImages) {
                            this.loadingProgress.emit({ done: true }); // Signal completion
                        }
                    };
                });
        });
    }


}
