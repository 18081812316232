<div class="relative w-full top-0 h-[4.6rem] bg-gray-900"></div>
<div [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'">
  <div class="px-10 grid grid-cols-2 items-center gap-5 mt-10">
    <div class="flex mb-10" *ngFor="let item of machines">
      <div class="w-1/2 pr-4">
        <a [routerLink]="['/', activeLang, 'machine', item.seoURL]">
          <img
            [src]="item.images[0]?.url"
            loading="lazy"
            width="100%"
            [alt]="item.altText"
          />
        </a>
      </div>
      <div class="w-1/2">
        <a
          class="hover:text-[#E60027]"
          [routerLink]="['/', activeLang, 'machine', item.seoURL]"
        >
          <h1 class="mt-2 fontBold">
            {{ item.name[activeLang] }}
          </h1>
        </a>
        <span [innerHTML]="item.description[activeLang] | truncate : 25"></span>
      </div>
    </div>
  </div>
</div>

<div class="my-60"></div>
