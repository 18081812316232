import {
  Injectable,
  Inject,
  makeStateKey,
  Renderer2,
  RendererFactory2,
  PLATFORM_ID,
} from "@angular/core";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { GeneralService } from "app/services/general.service";
import { Machine } from "app/shared/machine";
import { Meta, TransferState, Title } from "@angular/platform-browser";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class MachineResolver implements Resolve<Machine> {
  private renderer: Renderer2;
  private MACHINE_KEY = makeStateKey<Machine>("Machine");

  constructor(
    private machineService: GeneralService,
    private meta: Meta,
    private title: Title,
    private transferState: TransferState,
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    // Create a specific renderer instance for this class
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Machine> {
    const lang = route.paramMap.get("lang");
    const machineId = route.paramMap.get("id");

    // If we already have the machine data in transferState, retrieve it
    if (this.transferState.hasKey(this.MACHINE_KEY)) {
      const machineData = this.transferState.get<Machine>(
        this.MACHINE_KEY,
        null
      );
      if (machineData && machineId === machineData._id) {
        this.transferState.remove(this.MACHINE_KEY);
        this.updateMetaTags(machineData, lang);
        return of(machineData);
      }
    }

    // Otherwise, fetch fresh data
    return this.machineService
      .getItem("machine/getMachineByName", machineId)
      .pipe(
        switchMap((machineData) => {
          this.transferState.set(this.MACHINE_KEY, machineData);
          this.updateMetaTags(machineData, lang);
          return of(machineData);
        })
      );
  }

  /**
   * Update meta tags (title, description, etc.) and inject a VideoObject schema
   */
  private updateMetaTags(machineData: Machine, lang: string): void {
    if (!machineData) {
      return;
    }

    try {
      // --- METAS ---
      this.title.setTitle(machineData.name[lang]);
      this.meta.updateTag({
        property: "og:title",
        content: machineData.metaTag[lang],
      });
      this.meta.updateTag({
        property: "og:description",
        content: machineData.metaDescription[lang],
      });
      if (machineData.images && machineData.images.length > 0) {
        this.meta.updateTag({
          property: "og:image",
          content: machineData.images[0].url,
        });
      }

      // --- JSON-LD VIDEO SCHEMA ---
      // Only if there's a YouTube video present, build the schema from the first one
      if (machineData.youTubeVideos && machineData.youTubeVideos.length > 0) {
        const firstVideo = machineData.youTubeVideos[0];
        // Build the YouTube embed and thumbnail URLs
        const embedUrl = `https://www.youtube.com/embed/${firstVideo.url}`;
        const youTubeThumbnailUrl = `https://img.youtube.com/vi/${firstVideo.url}/hqdefault.jpg`;

        this.addVideoSchema({
          name: machineData.name[lang],
          description: machineData.metaDescription[lang],
          thumbnailUrl: youTubeThumbnailUrl,
          embedUrl: embedUrl,
          uploadDate: machineData.createdAt,
          duration: "PT0H9M51S", // Or dynamically compute the real duration
          regionsAllowed: ["IN"],
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Dynamically create a <script type="application/ld+json"> for VideoObject
   */
  private addVideoSchema(opts: {
    name: string;
    description: string;
    thumbnailUrl: string;
    embedUrl: string;
    uploadDate: string;
    duration: string;
    regionsAllowed: string[];
  }) {
    if (!isPlatformBrowser(this.platformId)) {
      // We’re on the server, so do nothing or handle differently
      return;
    }
    // Give the script a unique ID, for example:
    const scriptId = "video-schema";

    // Check if a script with this ID already exists
    const existingVideoScript = this.document.getElementById(scriptId);
    if (existingVideoScript) {
      // Remove only that specific script node
      this.renderer.removeChild(this.document.head, existingVideoScript);
    }

    const videoObjectJson = {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      name: opts.name,
      description: opts.description,
      thumbnailUrl: [opts.thumbnailUrl],
      uploadDate: opts.uploadDate,
      duration: opts.duration,
      embedUrl: opts.embedUrl,
      regionsAllowed: opts.regionsAllowed.map((region) => ({
        "@type": "Place",
        name: region,
      })),
      publication: {
        "@type": "BroadcastEvent",
        isLiveBroadcast: false,
        startDate: opts.uploadDate,
      },
    };

    const script = this.renderer.createElement("script");
    script.id = scriptId;
    script.type = "application/ld+json";
    script.text = JSON.stringify(videoObjectJson);

    // Append to <head> (or <body>, depending on preference)
    this.renderer.appendChild(this.document.head, script);
  }
}
