import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import {
    Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { GeneralService } from 'app/services/general.service';
import { BlogClass } from 'app/shared/blogs';
import { NewsClass } from 'app/shared/news';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})


@Injectable({ providedIn: 'root' })
export class BlogResolver implements Resolve<BlogClass> {
    constructor(private generalService: GeneralService, private meta: Meta, private title: Title) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BlogClass> {
        const id = route.params['id'];
        const lang = route.params['lang'];
        return this.generalService.getItem('blogs', id).pipe(
            map((blog: any) => {
                this.title.setTitle(blog['title']?.[lang]);
                this.meta.updateTag({ property: 'og:title', content: blog['title']?.[lang] });
                this.meta.updateTag({ property: 'og:description', content: blog['metaDescription']?.[lang] });
                this.meta.updateTag({ property: 'og:image', content: blog['image']['url'] });
                if (blog.noIndex) {
                    this.meta.addTag({ name: 'robots', content: 'noindex' });
                }
                if (blog.noFollow) {
                    this.meta.addTag({ name: 'robots', content: 'nofollow' });
                }
                return blog as BlogClass; // Assuming BlogClass is the correct type for your blog objects
            })
        );
    }
}


@Injectable({ providedIn: 'root' })
export class BlogsResolver implements Resolve<any[]> {
    constructor(private generalService: GeneralService) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
        return this.generalService.getItems('blogs'); // Send as query parameter
    }
}



@Injectable({ providedIn: 'root' })
export class OneNewsResolver implements Resolve<NewsClass> {
    constructor(private generalService: GeneralService, private meta: Meta, private title: Title) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<NewsClass> {
        const id = route.params['id'];
        const lang = route.params['lang'];
        return this.generalService.getItem('news', id).pipe(
            map((news: any) => {
                this.title.setTitle(news['title']?.[lang]);
                this.meta.updateTag({ property: 'og:title', content: news['title']?.[lang] });
                this.meta.updateTag({ property: 'og:description', content: news['description']?.[lang] });
                this.meta.updateTag({ property: 'og:image', content: news['image']['url'] });
                if (news.noIndex) {
                    this.meta.addTag({ name: 'robots', content: 'noindex' });
                }
                if (news.noFollow) {
                    this.meta.addTag({ name: 'robots', content: 'nofollow' });
                }
                return news as NewsClass; // Assuming NewsClass is the correct type for your news objects
            })
        );
    }
}



@Injectable({ providedIn: 'root' })
export class NewsResolver implements Resolve<NewsClass[]> {
    constructor(private generalService: GeneralService) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<NewsClass[]> {
        return this.generalService.getItems('news'); // Send as query parameter
    }
}

