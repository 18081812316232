<div *ngIf="browserMood">
  <video
    class="w-full min-h-[268px] lg:min-h-[300px] xl:min-h-[500px]"
    autoplay
    muted
    loop
    playsinline
    preload="auto"
    onloadedmetadata="this.muted = true"
  >
    <source [src]="getVideoURL()" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>
<div [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'">
  <div class="text-white text-center relative -top-48 lg:-top-[22rem]">
    <div class="flex justify-center items-center">
      <p
        class="rounded-md lg:rounded-lg bg-[#e60026] px-2 text-lg lg:text-3xl font-extrabold tracking-widest"
      >
        Zirve
      </p>
      <p class="text-lg lg:text-3xl font-extrabold pl-2 tracking-wider">
        Packaging
      </p>
    </div>
    <div class="flex items-center justify-center mt-4">
      <app-search class="w-5/6 lg:w-[45vw] xl:w-[40vw]"></app-search>
    </div>
  </div>
  <div
    class="w-full lg:w-5/6 xl:w-4/6 h-24 text-center relative grid grid-cols-1 lg:flex items-center shadow-md justify-center mx-auto -mt-24 lg:-mt-36 bg-[#EDEDED] rounded-xl lg:rounded-3xl"
  >
    <p class="text-lg lg:text-2xl pr-5">
      <span *ngIf="activeLang === 'TR'"
        ><span class="text-[#e60027]"> TÜRKİYE’</span>DE</span
      >

      {{ "madeInTr" | transloco }}
      <span *ngIf="activeLang !== 'TR'" class="text-[#e60027]">
        {{ "turkiye" | transloco }}</span
      >
    </p>
    <button
      [routerLink]="['/', activeLang, 'about_company']"
      class="bg-transparent max-sm:mx-3 hover:bg-gray-950 text-gray-950 font-semibold hover:text-white py-2 px-6 border border-gray-950 hover:border-transparent rounded-full"
    >
      {{ "discover" | transloco }} -->
    </button>
  </div>
  <div class="lg:flex mt-16">
    <div class="lg:w-1/3">
      <img class="w-full min-h-[200px]" src="assets/home/machine.webp" alt="" />
      <div class="-mt-28 ml-7">
        <p class="font-medium text-3xl pb-5">{{ "byMachine" | transloco }}</p>
        <a
          [routerLink]="['/', activeLang, 'equipment_categories']"
          class="bg-transparent hover:bg-gray-950 text-gray-950 hover:text-white py-1 px-8 border border-gray-950 hover:border-transparent rounded-full"
        >
          {{ "discoverAll" | transloco }}
        </a>
      </div>
    </div>
    <div class="lg:w-1/3 max-lg:my-5">
      <img class="w-full min-h-[200px]" src="assets/home/product.webp" alt="" />
      <div class="-mt-28 ml-7">
        <p class="font-medium text-3xl pb-5">{{ "byProduct" | transloco }}</p>
        <a
          [routerLink]="['/', activeLang, 'search', 'product']"
          class="bg-transparent hover:bg-gray-950 text-gray-950 hover:text-white py-1 px-8 border border-gray-950 hover:border-transparent rounded-full"
        >
          {{ "discoverAll" | transloco }}
        </a>
      </div>
    </div>
    <div class="lg:w-1/3">
      <img class="w-full min-h-[200px]" src="assets/home/back.webp" alt="" />
      <div class="-mt-28 ml-7">
        <p class="font-medium text-3xl pb-5">{{ "byBag" | transloco }}</p>
        <a
          [routerLink]="['/', activeLang, 'search', 'bag']"
          class="bg-transparent hover:bg-gray-950 text-gray-950 hover:text-white py-1 px-8 border border-gray-950 hover:border-transparent rounded-full"
        >
          {{ "discoverAll" | transloco }}
        </a>
      </div>
    </div>
  </div>
  <div class="bg-[#ECEFF6] py-10 mt-9">
    <div class="w-full lg:w-2/3 mx-auto flex text-center">
      <div class="w-1/4">
        <p class="text-lg lg:text-3xl">+{{ numbers[0].animatedValue }}</p>
        <p class="text-sm lg:text-lg">{{ "machine" | transloco }}</p>
      </div>
      <div class="w-1/4">
        <p class="text-lg lg:text-3xl">+{{ numbers[1].animatedValue }}</p>
        <p class="text-sm lg:text-lg">{{ "units" | transloco }}</p>
      </div>
      <div class="w-1/4">
        <p class="text-lg lg:text-3xl">+{{ numbers[2].animatedValue }}</p>
        <p class="text-sm lg:text-lg">{{ "customer" | transloco }}</p>
      </div>
      <div class="w-1/4">
        <p class="text-lg lg:text-3xl">
          +{{ numbers[3].animatedValue | number : "1.0-0" }}
        </p>
        <p class="text-sm lg:text-lg">{{ "countries" | transloco }}</p>
      </div>
    </div>
  </div>
  <div class="flex items-center justify-center mt-44">
    <p
      class="text-center inline-block text-xl border border-black px-5 py-1 rounded-full"
    >
      {{ "insightsNews" | transloco }}
    </p>
  </div>
  <div class="px-3 xl:px-20">
    <div class="w-full flex items-center justify-end my-5">
      <a
        class="ml-auto text-xl hover:text-[#E60027]"
        [routerLink]="['/', activeLang, 'news']"
      >
        {{ "news" | transloco }}
      </a>
      <svg
        width="20"
        height="16"
        viewBox="0 0 27 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.7071 8.70711C27.0976 8.31658 27.0976 7.68342 26.7071 7.29289L20.3431 0.928932C19.9526 0.538408 19.3195 0.538408 18.9289 0.928932C18.5384 1.31946 18.5384 1.95262 18.9289 2.34315L24.5858 8L18.9289 13.6569C18.5384 14.0474 18.5384 14.6805 18.9289 15.0711C19.3195 15.4616 19.9526 15.4616 20.3431 15.0711L26.7071 8.70711ZM0 9H26V7H0V9Z"
          fill="black"
        />
      </svg>
    </div>

    <div
      class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 mt-10"
    >
      <div class="shadow-lg rounded-t-xl h-full" *ngFor="let newsItem of news">
        <div class="w-full h-64 mx-auto overflow-hidden">
          <img
            *ngIf="newsItem.image.url !== 'none'"
            loading="lazy"
            [src]="newsItem.image.url"
            alt="Image 1"
            class="center object-cover w-full min-h-[320px]"
          />
        </div>
        <div class="flex items-center justify-between p-2 text-[#5B5B5B]">
          <span> {{ newsItem.date | date : "M/d/yyy" }} </span>
        </div>
        <div class="p-2">
          <a
            class="flex items-center hover:text-[#E60027] cursor-pointer"
            [routerLink]="['/', activeLang, 'news', newsItem._id]"
          >
            <p class="font-bold" [dir]="activeLang === 'AR' ? 'rtl' : 'ltr'">
              {{ newsItem.title[activeLang] }}
            </p>
          </a>
        </div>
      </div>
    </div>

    <div class="flex items-center justify-center mt-44">
      <p
        class="text-center inline-block text-xl border border-black px-5 py-1 rounded-full"
      >
        {{ "suggestedBlogs" | transloco }}
      </p>
    </div>
    <div *ngIf="blogs" class="hidden lg:flex items-center mt-20">
      <div class="w-1/2">
        <img
          class="mx-auto w-2/3 min-h-[320px]"
          *ngIf="blogs[0]?.image.url !== 'none'"
          [src]="blogs[0]?.image?.url"
          loading="lazy"
          alt=""
        />
      </div>
      <div class="w-1/2">
        <div class="p-5" [dir]="activeLang === 'AR' ? 'rtl' : 'ltr'">
          <a
            class="flex items-center font-bold pt-5 hover:text-[#E60027]"
            [routerLink]="['/', activeLang, 'blogs', blogs[0]?._id]"
            aria-label="Learn more about {{ blogs[0]?.title }}"
          >
            <p class="w-3/6 text-2xl font-bold">
              {{ blogs[0]?.title[activeLang] }}
            </p>
          </a>

          <p class="text-xs w-5/6 text-[#424242] pt-3">
            <span
              [innerHTML]="blogs[0]?.description[activeLang] | truncate : 50"
            ></span>
          </p>
        </div>
      </div>
    </div>
    <div class="w-full flex items-center justify-end py-5">
      <a
        class="ml-auto text-xl hover:text-[#E60027]"
        [routerLink]="['/', activeLang, 'blogs']"
      >
        {{ "blogs" | transloco }}
      </a>
      <svg
        width="20"
        height="16"
        viewBox="0 0 27 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.7071 8.70711C27.0976 8.31658 27.0976 7.68342 26.7071 7.29289L20.3431 0.928932C19.9526 0.538408 19.3195 0.538408 18.9289 0.928932C18.5384 1.31946 18.5384 1.95262 18.9289 2.34315L24.5858 8L18.9289 13.6569C18.5384 14.0474 18.5384 14.6805 18.9289 15.0711C19.3195 15.4616 19.9526 15.4616 20.3431 15.0711L26.7071 8.70711ZM0 9H26V7H0V9Z"
          fill="black"
        />
      </svg>
    </div>
    <div
      *ngIf="blogs"
      class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 mt-10"
    >
      <div
        class="shadow-lg rounded-t-xl h-full"
        *ngFor="let item of blogs | slice : 1 : 5"
      >
        <div class="w-full h-64 mx-auto overflow-hidden">
          <img
            *ngIf="item.image.url !== 'none'"
            [src]="item.image.url"
            loading="lazy"
            alt="Image 1"
            class="center object-cover w-full min-h-[260px]"
          />
        </div>
        <div class="p-2">
          <a
            class="flex items-center pt-5 hover:text-[#E60027]"
            [routerLink]="['/', activeLang, 'blogs', item._id]"
          >
            <p
              [dir]="activeLang === 'AR' ? 'rtl' : 'ltr'"
              class="font-bold w-full text-center p-3"
            >
              {{ item.title[activeLang] }}
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div
    class="lg:mt-52 w-full lg:px-12 xl:px-52 py-5 bg-gradient-to-r from-white via-[#EAEAEA] to-white"
  >
    <div class="grid grid-cols-1 md:flex items-center justify-center">
      <div class="w-full md:w-1/2">
        <a
          class="cursor-pointer"
          [matTooltip]="'packagingCatalog' | transloco"
          [routerLink]="['/', activeLang, 'catalog', 'packaging']"
        >
          <img
            src="assets/home/catalogRed.webp"
            loading="lazy"
            width="100%"
            alt=""
          />
        </a>
      </div>
      <div class="w-full md:w-1/2 grid justify-center text-center">
        <div class="w-[6.5rem] mx-auto px-2 mb-5 rounded-lg bg-[#E60027]">
          <p class="font-extrabold text-4xl text-white">Zirve</p>
        </div>
        <p class="text-3xl tracking-[0.4rem]">
          PACKAGING
          <br />
          DIGITAL CATALOG
        </p>
        <svg
          class="mt-3"
          width="380"
          height="3"
          viewBox="0 0 380 3"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line y1="1.5" x2="380" y2="1.5" stroke="#004B88" stroke-width="3" />
        </svg>

        <a
          *ngIf="user"
          class="w-[6.5rem] mx-auto px-2 mt-5 rounded-sm bg-[#E60027] text-white"
          href="https://msr.zirvecompany.net/images/packaging.pdf"
          target="_blank"
        >
          Download
        </a>
      </div>
    </div>
  </div>

  <div class="flex items-center justify-center mt-36 mb-10">
    <p
      class="text-center inline-block text-xl border border-black px-5 py-1 rounded-full"
    >
      {{ "ourClients" | transloco }}
    </p>
  </div>

  <div class="xl:px-16">
    <swiper-container
      slides-per-view="1"
      pagination="true"
      autoplay
      rewind="true"
      speed="1500"
      autoplay-delay="10000"
    >
      <swiper-slide *ngFor="let client of clients">
        <img
          [src]="client.url"
          loading="lazy"
          class="object-contain"
          alt="Client"
        />
      </swiper-slide>
    </swiper-container>
  </div>
  <div class="flex items-center justify-center mt-36">
    <p
      class="text-center inline-block text-xl border border-black px-5 py-1 rounded-full"
    >
      {{ "ourPartners" | transloco }}
    </p>
  </div>
</div>
<div>
  <swiper-container
    loop="true"
    navigation="true"
    autoplay
    rewind="true"
    speed="1500"
    [breakpoints]="breakpoints"
  >
    <swiper-slide *ngFor="let partner of partners">
      <img
        [src]="partner?.url"
        loading="lazy"
        class="w-44 h-36 px-2 mx-auto object-contain"
        alt="Partner"
      />
    </swiper-slide>
  </swiper-container>
</div>

<div class="my-60"></div>
