// shared-data.service.ts

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SharedDataService {
    private selectedProduct: any | null = null;

    setSelectedProduct(product: any) {
        this.selectedProduct = product;
    }

    getSelectedProduct(): any | null {
        return this.selectedProduct;
    }
}