<form
  class="form-class rounded-2xl"
  *transloco="let t"
  [dir]="activeLang === 'AR' ? 'rtl' : 'ltr'"
>
  <input
    class="input-class rounded-xl arabic"
    type="text"
    [placeholder]="'searchBy' | transloco"
    id="searchID"
    #searchID
    [matAutocomplete]="auto"
    (input)="onKeySearch($event)"
  />
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option
      class="mat-option"
      *ngFor="let product of products"
      [value]="product.name[activeLang]"
    >
      <a
      [routerLink]="['/', activeLang, 'machine', product.seoURL]"
        (click)="resetArray()"
        class="w-full"
      >
        <div class="flex items-center">
          <img
            *ngIf="product.images[0]?.url && product.images[0]?.url !== 'none'"
            [src]="product.images[0]?.url"
            class="rounded-full w-10 mr-5"
          />
          <span>{{ product.name[activeLang] }}</span>
        </div>
      </a>
    </mat-option>
  </mat-autocomplete>
</form>
