import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { GeneralService } from 'app/services/general.service';
import { SharedDataService } from 'app/services/sharedData.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-part-overview',
  templateUrl: './part-overview.component.html',
  styleUrls: ['./part-overview.component.scss']
})
export class PartOverviewComponent implements OnInit, OnDestroy {
  product: any | null = null;
  id: string;
  name: string;
  machineName: string;
  activeLang: string = 'EN';
  private langChangesSubscription: Subscription;

  constructor(private sharedDataService: SharedDataService,
    private generalServices: GeneralService,
    private route: ActivatedRoute,

    private _translocoService: TranslocoService,

  ) { }

  ngOnInit() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
    });
    this.product = this.sharedDataService.getSelectedProduct();
    this.id = this.route.snapshot.paramMap.get('id');
    if (!this.product) {
      this.name = this.route.snapshot.paramMap.get('name');
      this.generalServices.getItem('machine', this.id).subscribe((res) => {
        this.machineName = res.name['EN']
        this.product = res.partes.find(el => el.title['EN'] === this.name);
      })
    }


  }



  ngOnDestroy(): void {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
  }
}