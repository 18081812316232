import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MetaTagResolver } from './resolvers/metaTitle.resolver';
import { HomeComponent } from './home/home.component';
import { ProductComponent } from './product/product.component';
import { ContactComponent } from './contact/contact.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { PartOverviewComponent } from './part-overview/part-overview.component';
import { EquipmentCategoriesComponent } from './equipment_categories/equipment_categories.component';
import { MachinesComponent } from './machines/machines.component';
import { EquipmentsResolver, MachinesResolver } from './resolvers/equipments.resolver';
import { BlogsComponent } from './blogs/blogs.component';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { NewsComponent } from './news/news.component';
import { NewsDetailsComponent } from './news-details/news-details.component';
import { BlogResolver, BlogsResolver, NewsResolver, OneNewsResolver } from './resolvers/news_blogs.resolver';
import { ByBagsProductsComponent } from './by-bags-products/by-bags-products.component';
import { ByBagResolver, ByProductResolver, ByProductsBagsResolver } from './resolvers/bags_products.resolver';
import { ByBagProductComponent } from './by-bag-product/by-bag-product.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ManagerWordComponent } from './manager-word/manager-word.component';
import { SearchMachinesComponent } from './search-machines/search-machines.component';
import { CatalogsComponent } from './catalogs/catalogs.component';
import { MachineResolver } from './resolvers/machine.resolver';
import { ComplaintsSuggestionsComponent } from './complaints-suggestions/complaints-suggestions.component';
import { PrivacyPolicyComponent } from './privacy_policies/privacy-policy/privacy-policy.component';
import { CookiesComponent } from './privacy_policies/cookies/cookies.component';
import { DataProtictionComponent } from './privacy_policies/data-protiction/data-protiction.component';

const routes: Routes = [
  { path: "", redirectTo: "EN/home", pathMatch: "full" },
  { path: ":lang/home", component: HomeComponent, resolve: { metaTag: MetaTagResolver, }, data: { pageName: 'home' } },
  { path: ":lang/machine/:id", component: ProductComponent, resolve: { machine: MachineResolver } },
  { path: ":lang/contact", component: ContactComponent, resolve: { metaTag: MetaTagResolver }, data: { pageName: 'contact' } },
  { path: ":lang/sign_in", component: SignInComponent, },
  { path: ":lang/sign_up", component: SignUpComponent, },
  { path: ":lang/dataProtection", component: DataProtictionComponent, },
  { path: ":lang/cookies", component: CookiesComponent, },
  { path: ":lang/privacyPolicy", component: PrivacyPolicyComponent, },
  { path: ":lang/part_overview/:id/:name", component: PartOverviewComponent, },
  { path: ":lang/equipment_categories", component: EquipmentCategoriesComponent, resolve: { equipments: EquipmentsResolver, metaTag: MetaTagResolver }, data: { pageName: 'equipment_categories' } },
  { path: ":lang/machines/:id", component: MachinesComponent, resolve: { machines: MachinesResolver, metaTag: MetaTagResolver }, data: { pageName: 'machines' } },
  { path: ":lang/blogs", component: BlogsComponent, resolve: { blogs: BlogsResolver, metaTag: MetaTagResolver }, data: { pageName: 'blogs' } },
  { path: ":lang/blogs/:id", component: BlogDetailsComponent, resolve: { blog: BlogResolver }, },
  { path: ":lang/news", component: NewsComponent, resolve: { news: NewsResolver, metaTag: MetaTagResolver }, data: { pageName: 'news' } },
  { path: ":lang/news/:id", component: NewsDetailsComponent, resolve: { newsDetails: OneNewsResolver, }, },
  { path: ":lang/search/:type", component: ByBagsProductsComponent, resolve: { items: ByProductsBagsResolver } },
  { path: ":lang/bag/:id", component: ByBagProductComponent, resolve: { searchMachines: ByBagResolver } },
  { path: ":lang/product/:id", component: ByBagProductComponent, resolve: { searchMachines: ByProductResolver } },
  { path: ":lang/about_company", component: AboutUsComponent, resolve: { metaTag: MetaTagResolver }, data: { pageName: 'about_company' } },
  { path: ":lang/general_manager", component: ManagerWordComponent, resolve: { metaTag: MetaTagResolver }, data: { pageName: 'general_manager' } },
  { path: ":lang/search_machines", component: SearchMachinesComponent, },
  { path: ":lang/catalog/:type", component: CatalogsComponent, },
  { path: ":lang/complaints_suggestions", component: ComplaintsSuggestionsComponent, },
  { path: '**', redirectTo: 'EN/home' },
];

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
  initialNavigation: 'enabledBlocking'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerConfig)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
