import { Injectable } from '@angular/core';
import {
    Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { GeneralService } from 'app/services/general.service';
import { Equipment } from 'app/shared/equipment';
import { Machine } from 'app/shared/machine';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})


// @Injectable({ providedIn: 'root' })
// export class MachineResolver implements Resolve<Machine> {
//     constructor(private generalService: GeneralService) { }
//     resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Machine> {
//         const id = route.params['id']; // Get the id from the route parameters
//         return this.generalService.getItem('machine', id); // Send as query parameter
//     }
// }



@Injectable({ providedIn: 'root' })
export class MachinesResolver implements Resolve<Machine[]> {
    constructor(private generalService: GeneralService) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Machine[]> {
        const id = route.params['id']; // Get the id from the route parameters
        return this.generalService.getItems(`machine/byCate/${id}`); // Send as query parameter
    }
}



@Injectable({ providedIn: 'root' })
export class EquipmentsResolver implements Resolve<Equipment[]> {
    constructor(private generalService: GeneralService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Equipment[]> {
        return this.generalService.getItems('main_category'); // Send as query parameter
    }
}
